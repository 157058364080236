.login{
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
}

.login-left{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient( rgba(17,68,175,1) 0%, rgba(46,98,208,1) 36%, rgba(142,176,249,1) 100%); */
    background: linear-gradient(rgba(17,68,175,1) 0%, rgba(77,219,51,1) 100%);
}

/* .login-left h1{
    color: white;
    font-size: 55px;
    padding-left: 10px;
} */

.login-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-top: 15%;
}

.login-right form{
    width: 80%;
    padding-top: 30px;
    padding-bottom: 20px;
}