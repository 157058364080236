.theSideNav{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

div img{
    padding-left: 10px;
    padding-bottom: 0px;
}

div ul{
    list-style: none;
    padding: 0px;
}

div ul li{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

div ul li:hover{
    background: rgb(75, 111, 187);
}

div ul li:active{
    background: rgb(75, 111, 187);
}

a {
    text-decoration: none;
    color: white;
}

ul li a:hover{
    background-color: rgb(75, 111, 187);
    color: #fff;
}

.active a{
    background-color: rgb(75, 111, 187);
    color: #fff;
 }

 #navlogo{
    width: 200px;
    margin-top: -70px;
    margin-bottom: -40px;
    margin-left: -20px;
    margin-right: -20px;
 }