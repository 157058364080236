.visits-layout{
    padding: 30px;
}

.visit-cards{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    gap: 20px
}

.modal-footer{
    cursor: pointer;
    color: blue;
}

