.client-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 25px;
    padding-right: 50px;
}

.client-nav input{
    width: 300px
}

.client-nav button{
    background-color: rgba(77,219,51,1);
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.client-body{
    padding: 25px;
}

.actionIcons{
    padding-left: 10px;
    cursor: pointer;
}

.archiveButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.archiveButtons button {
    border: 0px;
    border-radius: 5px;
    font-size: 20px;
}