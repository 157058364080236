.clientPage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
}

.goBack{
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px;
}

.goBack p{
    margin-bottom: 0em;
}

.clientHeader{
    margin-bottom: 30px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #888888;
    border-radius: 10px;
    /* box-shadow: 10px 10px 8px #888888; */
}

.clientBody{
    display: flex;
    flex-direction: row;
    justify-content:left;
    gap: 50px;
    width: 100%;
    min-height: 70vh;
}

.clientProfile{
    border: 1px solid #888888;
    border-radius: 10px;
    box-shadow: 10px 10px 8px #888888;
    padding: 20px;
    max-width: 25%;
    min-width: 25%
}

.clientProfile h3{
    text-decoration: underline;
    padding-bottom: 20px;
}

.clientProfileHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.clientVisits{
    /* border: 1px solid #888888;
    border-radius: 10px; */
    padding: 20px;
    min-width: 65%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.clientVisitsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.clientVisitsHeader h3{
    text-decoration: underline;
    padding-bottom: 20px;
}

.clientVisitsHeader button{
    background-color: rgba(77,219,51,1);
    border: 1px solid rgb(247, 244, 244);
    border-radius: 5px;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.clientVisitsCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    gap: 30px
}

.card{
    cursor: pointer;
}

.card:hover{
    background-color: rgb(77,219,51);
}

.upcomingVisit{
    padding-bottom: 20px;
}

div span{
    padding-left: 15px;
}

