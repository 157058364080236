.newVisitLayout{
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10px;
    border-left: 3px solid rgb(17,68,175);
    border-right: 3px solid rgb(17,68,175);
    padding: 40px;
}

.newVisitLayout h4{
    margin-top: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 3px solid black;

}

.newVisitLayout h3{
    border-bottom: 3px solid black;
    padding-bottom: 10px;
    margin-bottom: 50px;
    color: rgb(17,68,175);
}

.removeIcon{
    padding-left: 10px;
}

#newVisitSubmit{
    margin-top: 50px;
    padding-top: 30px;
    border-top: 3px solid black;
}