.onboardLayout{
    border: 1px solid #BFBFBF;
    border-radius: 10px;
    width: 700px;
    margin: auto;
    background: white;
    padding: 30px;

}

.overallLayout{
    background: linear-gradient(rgba(17,68,175,1) 0%, rgba(77,219,51,1) 100%);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}

.thanksLayout{
    border: 1px solid #BFBFBF;
    border-radius: 10px;
    width: 700px;
    margin: auto;
    background: white;
    padding: 30px;

}

.progressBar div{
    background-color: rgba(17,68,175);
    height: 2px;
    margin-bottom: 20px;
}