.visitLayout{
    padding: 30px 70px 0px 20px;
    margin-left: 50px;
    border-left: 1px solid gray;
    min-height: 100vh;
}

.visitLayoutHeader{
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.visitLayoutHeader button{
    background-color: rgba(77,219,51,1);
    border-radius: 5px;
    margin-left: 5px;
}

.visitLayout li{
    list-style:circle;
    margin-left: 20px;
}

.border {
    border-right: 1px solid gray;
}

.goBackVisit{
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px 5px 5px 0px;
}

.goBackVisit p{
    margin-bottom: 0em;
}

.icons{
    cursor: pointer;
}