.home {
    display: flex;
    flex-direction: column;
}
.home-top{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-bottom: solid 1px black;
}

.home-bottom{
    display: flex;
    justify-content: left;
    gap: 40px;
    flex-wrap: wrap;
    padding: 30px;
}

.home-bottom-clients{
    width: 700px;
    height: 300px;
    border: solid 1px black;
    border-radius: 20px;
}

.home-bottom-clients-header{
    width: 100%;
    background: rgba(17,68,175,1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 20px;
}