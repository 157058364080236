#layout{
    display: flex;
    width: 100%;
    min-height: 100vh;
}

#layout .layout-left{
    width: 250px;
    background: linear-gradient(rgba(17,68,175,1) 0%, rgba(77,219,51,1) 100%);
    min-height: 100vh;
    padding: 30px;
    color: white;
  
}

#layout .layout-right{
    width: 100%;
}